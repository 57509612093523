<template>
  <div class="container">
    <h4 class="text-center my-4">Семестровый календарь</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <div v-for="(s, sIndex) in semesterCalendar" :key="sIndex" class="border p-3 my-2">
        <div class="row mt-2">
          <div class="col-md-3"><b>Календарь</b></div>
          <div class="col-md-4"><b>Курс {{ s.study_course }} ({{ s.study_level }})</b></div>
        </div>

        <div class="row mt-2">
          <div class="col-md-3">Семестр</div>
          <div class="col-md-4">{{ s.semester_type }}</div>
        </div>

        <div class="row mt-2">
          <div class="col-md-3">Начало выбора расписания</div>
          <div class="col-md-4">{{ s.start_choose_schedule?.substr(0, 16) }}</div>
        </div>

        <div class="row mt-2">
          <div class="col-md-3">Конец выбора расписания</div>
          <div class="col-md-4">{{ s.end_choose_schedule?.substr(0, 16) }}</div>
        </div>

        <div class="row mt-2">
          <div class="col-md-3">Начало ВСК1</div>
          <div class="col-md-4">{{ s.vsk1_start_date?.substr(0, 16) }}</div>
        </div>

        <div class="row mt-2">
          <div class="col-md-3">Конец ВСК1</div>
          <div class="col-md-4">{{ s.vsk1_end_date?.substr(0, 16) }}</div>
        </div>

        <div class="row mt-2">
          <div class="col-md-3">Конец ВСК2</div>
          <div class="col-md-4">{{ s.vsk2_end_date?.substr(0, 16) }}</div>
        </div>

        <div class="row mt-2">
          <div class="col-md-3">Начало экзаменов</div>
          <div class="col-md-4">{{ s.start_exam_date?.substr(0, 16) }}</div>
        </div>

        <div class="row mt-2">
          <div class="col-md-3">Конец экзаменов</div>
          <div class="col-md-4">{{ s.end_exam_date?.substr(0, 16) }}</div>
        </div>

        <div class="row mt-2">
          <div class="col-md-3">Начало каникул</div>
          <div class="col-md-4">{{ s.start_holiday_day?.substr(0, 16) }}</div>
        </div>

      </div>


      <Button v-if="!form" icon="pi pi-plus" class="my-4" @click="addCalendar"/>
      <div v-else>
        <div class="p-2">

          <div class="row mt-4">
            <label class="col-md-3 col-form-label">Семестр</label>
            <div class="col-md-4">
              <select class="form-control form-select" v-model="form.semester_type_id">
                <option :value="0" hidden>Выберите</option>
                <option v-for="(item, index) in semesters" :key="index" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mt-4">
            <label class="col-md-3 col-form-label">Начало выбора расписания</label>
            <div class="col-md-4">
              <input type="datetime-local" class="form-control" v-model="form.start_choose_schedule">
            </div>
          </div>

          <div class="row mt-4">
            <label class="col-md-3 col-form-label">Конец выбора расписания</label>
            <div class="col-md-4">
              <input type="datetime-local" class="form-control" v-model="form.end_choose_schedule">
            </div>
          </div>

          <div class="row mt-4">
            <label class="col-md-3 col-form-label">Начало ВСК1</label>
            <div class="col-md-4">
              <input type="datetime-local" class="form-control" v-model="form.vsk1_start_date">
            </div>
          </div>

          <div class="row mt-4">
            <label class="col-md-3 col-form-label">Конец ВСК1</label>
            <div class="col-md-4">
              <input type="datetime-local" class="form-control" v-model="form.vsk1_end_date">
            </div>
          </div>

          <div class="row mt-4">
            <label class="col-md-3 col-form-label">Конец ВСК2</label>
            <div class="col-md-4">
              <input type="datetime-local" class="form-control" v-model="form.vsk2_end_date">
            </div>
          </div>

          <div class="row mt-4">
            <label class="col-md-3 col-form-label">Начало экзаменов</label>
            <div class="col-md-4">
              <input type="datetime-local" class="form-control" v-model="form.start_exam_date">
            </div>
          </div>

          <div class="row mt-4">
            <label class="col-md-3 col-form-label">Конец экзаменов</label>
            <div class="col-md-4">
              <input type="datetime-local" class="form-control" v-model="form.end_exam_date">
            </div>
          </div>

          <div class="row mt-4">
            <label class="col-md-3 col-form-label">Начало каникул</label>
            <div class="col-md-4">
              <input type="datetime-local" class="form-control" v-model="form.start_holiday_day">
            </div>
          </div>

          <div class="row mt-4">
            <label class="col-md-3 col-form-label">Календарь</label>
            <div class="col-md-4">
              <select class="form-control form-select" v-model="form.education_calendar_id">
                <option :value="0" hidden>Выберите</option>
                <option v-for="(item, index) in educationCalendar" :key="index" :value="item.id">
                  Курс {{ item.study_course }} - {{ item.name }}
                </option>
              </select>
            </div>
          </div>

        </div>

        <Button icon="pi pi-save" label="Сохранить" class="my-4" @click="saveCalendar"/>
      </div>

    </div>


  </div>
</template>

<script>

import {mapActions} from 'vuex'

export default {
  name: 'SemesterCalendar',
  data() {
    return {
      loading: true,
      semesters: [],
      educationCalendar: [],
      form: null,
      semesterCalendar: []
    }
  },
  computed: {},
  methods: {
    ...mapActions('semesterCalendar', ['GET_SEMESTER', 'GET_EDUCATION_CALENDAR', 'GET_SEMESTER_CALENDAR', 'POST_SEMESTER_CALENDAR']),
    addCalendar() {
      this.form = {}
    },
    async saveCalendar() {
      const res = await this.POST_SEMESTER_CALENDAR(this.form)

      if (res) {
        this.form = null
        await this.getSemesterCalendar()
        this.$message({title: 'Сохранено'})
      }
    },
    async getSemesterCalendar() {
      this.semesterCalendar = await this.GET_SEMESTER_CALENDAR()
    }
  },
  async mounted() {
    this.semesters = await this.GET_SEMESTER()
    this.educationCalendar = await this.GET_EDUCATION_CALENDAR()

    await this.getSemesterCalendar()
    this.loading = false
  }
}

</script>

<style scoped>

</style>
